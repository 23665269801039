* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: linear-gradient(#141E30, #243B55);
  color: white;
}
