.App_Grid__2er2D {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto 38em auto auto;
  grid-template-rows: 10em 45em 10em;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.App_Child__1k9h3 {
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));
  /* border: 0.3em solid rgba(23, 60, 139, 0.514); */
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 2.5em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
}

.App_curtain__1nkpR {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}

.Staking_Staking__1qGtX h1 {
  margin-top: 1.2em;
}
.Staking_icon__JJV4o {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Staking_inputDiv__1MDgO {
  padding: 3em;
  height: 5em;
  align-items: center;
}

.Staking_input__2ig-R {
  height: 3em;
  width: 37em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.Staking_totals__1DfBe {
  margin-top: 5em;
  height: 3em;
}

.Staking_goMax__3FLbR {
  cursor: pointer;
}

.Staking_stakeButton__2K68g {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.Staking_inputselect__21Mfs {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  /* border: white solid 0.05em; */
  border-radius: 0.5em;
  /* height: 3em; */
  width: 22em;
  margin-top: 1em;
  cursor: pointer;
}

.Staking_stakeButton__2K68g:hover {
  background-color: #2675cf;
}

.Staking_unstakeButton__2vPIa {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.Staking_stakeButton__2K68g:focus {
  outline: none;
 }

.Staking_unstakeButton__2vPIa:hover {
  background-color: #1c375f;
}

.Staking_unstakeButton__2vPIa:focus {
 outline: none;
}

.Staking_stakeIcon__3fHVU {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Staking_stakeButton__2K68g p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.Staking_unstakeButton__2vPIa p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.Staking_inputt__1XW1c{
  width: 15em;
  padding-right: 5.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 0.25rem;
    /* border-top-left-radius: 0.25rem; */
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    /* border-bottom-left-radius: 0.25rem; */
    line-height: inherit;
    font-family: inherit;
    font-size: 100%;

}

.Staking_bth__Iji87{
  flex-direction: row-reverse;
}

.Staking_selectt__P-SY8{
  margin-left: 298px;
  width: 15em;
  margin-top: -39px;
  border-color: none !important;
  color: black !important;
}


.Staking_css-1pahdxg-control__2FJmW:hover{
  border-color: rgb(0, 24, 44) !important;
}

.AdminTesting_for_testing__2NrPp {
  margin-top: 11em;
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.AdminTesting_for_testing__2NrPp p {
  padding-top: 1em;
  color: #37a99e;
}

.AdminTesting_for_testing__2NrPp button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.AdminTesting_for_testing__2NrPp button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.AdminTesting_for_testing__2NrPp button:focus {
  outline: none;
}

.AdminTesting_network__1mUz0 p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: linear-gradient(#141E30, #243B55);
  color: white;
}

