.Staking h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding: 3em;
  height: 5em;
  align-items: center;
}

.input {
  height: 3em;
  width: 37em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.totals {
  margin-top: 5em;
  height: 3em;
}

.goMax {
  cursor: pointer;
}

.stakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.inputselect {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  /* border: white solid 0.05em; */
  border-radius: 0.5em;
  /* height: 3em; */
  width: 22em;
  margin-top: 1em;
  cursor: pointer;
}

.stakeButton:hover {
  background-color: #2675cf;
}

.unstakeButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.stakeButton:focus {
  outline: none;
 }

.unstakeButton:hover {
  background-color: #1c375f;
}

.unstakeButton:focus {
 outline: none;
}

.stakeIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.stakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.unstakeButton p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}

.inputt{
  width: 15em;
  padding-right: 5.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 0.25rem;
    /* border-top-left-radius: 0.25rem; */
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    /* border-bottom-left-radius: 0.25rem; */
    line-height: inherit;
    font-family: inherit;
    font-size: 100%;

}

.bth{
  flex-direction: row-reverse;
}

.selectt{
  margin-left: 298px;
  width: 15em;
  margin-top: -39px;
  border-color: none !important;
  color: black !important;
}


.css-1pahdxg-control:hover{
  border-color: rgb(0, 24, 44) !important;
}
