.for_testing {
  margin-top: 11em;
  padding-top: 1em;
  background-color: rgba(95, 158, 160, 0.034);
  height: 24em;
  border-radius: 0 0 2em 2em;
}

.for_testing p {
  padding-top: 1em;
  color: #37a99e;
}

.for_testing button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 15em;
  cursor: pointer;
}

.for_testing button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.for_testing button:focus {
  outline: none;
}

.network p {
  color: rgb(119, 157, 201);
  font-size: 0.8em;
}
